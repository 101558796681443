/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'
import styled from 'styled-components'
import { graphql, useStaticQuery } from 'gatsby'
import { motion } from 'framer-motion'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Navigation } from 'swiper/core'
import Plaatjie from '@ubo/plaatjie'

// Stylesheets
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'

// Images
import SwiperArrow from 'img/swiperarrow.svg'

// Element
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Section = styled.section`
  & .custom-swiper-button-prev {
    content: url(${SwiperArrow});
    transform: rotate(180deg);
  }

  & .custom-swiper-button-next {
    content: url(${SwiperArrow});
  }

  & .swiper-button-disabled {
    opacity: 0.25;
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 48px;
    }
  }
`

const Post = styled(NavLink)`
  color: ${({ theme }) => theme.color.light};

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: ${({ theme }) => theme.font.family.primaryMedium};
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    @media (min-width: 992px) {
      font-size: 24px;
    }
    @media (max-width: 991px) {
      font-size: 18px;
      line-height: 22px;
    }
  }

  & span {
    @media (min-width: 576px) {
      font-size: 18px;
    }
    @media (max-width: 575px) {
      font-size: 12px;
    }
  }

  &:hover {
    color: ${({ theme }) => theme.color.light};
    & img {
      transform: scale(1.1);
      transition-property: transform;
      transition-duration: 0.3s;
      transition-timing-function: ease-in-out !important;
    }
  }
  @media (min-width: 1400px) {
    min-height: 425px;
  }
  @media (max-width: 1399px) {
    min-height: 355px;
  }
  @media (max-width: 1199px) {
    min-height: 295px;
  }
  @media (max-width: 991px) {
    min-height: 215px;
  }
  @media (max-width: 767px) {
    min-height: 245px;
  }
  @media (max-width: 575px) {
    height: 200px;
    min-height: unset;
  }
  @media (max-width: 375px) {
    height: 150px;
    min-height: unset;
  }
`

const PostInner = styled.div`
  z-index: 3;
  background: rgb(29, 26, 26);
  background: linear-gradient(
    0deg,
    rgba(29, 26, 26, 0.727328431372549) 70%,
    rgba(255, 255, 255, 0) 100%
  ) !important;
  @media (min-width: 576px) {
    margin-top: -6.9rem;
  }
  @media (max-width: 575px) {
    margin-top: -5.3rem;
  }
`

const ImageWrapper = styled.div`
  bottom: 0;
  left: 0;
  width: 100%;

  @media (min-width: 576px) {
    position: absolute;
  }
  @media (max-width: 575px) {
    position: relative;
  }
`

const Image = styled(Plaatjie)`
  position: relative;
  z-index: -1;
`

interface RelatedProjectsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_RelatedPosts
  location: any
}

const RelatedProjects: React.FC<RelatedProjectsProps> = ({
  fields,
  location,
}) => {
  const {
    allWpProject,
    // eslint-disable-next-line
  } = useStaticQuery<GatsbyTypes.relatedProjectsQueryQuery>(graphql`
    query relatedProjectsQuery {
      allWpProject(sort: { order: ASC, fields: date }) {
        edges {
          node {
            ...generalProjectFragment
          }
        }
      }
    }
  `)

  const posts: any = allWpProject.edges

  const relatedPosts: Array<any> = []

  posts
    .filter((post: any) =>
      post.node.projectcategories.nodes
        .map((node: any) => node.databaseId)
        .includes(fields.taxonomyprojects?.databaseId)
    )
    .forEach((post: any) => {
      relatedPosts.push(post)
    })

  SwiperCore.use([Navigation])

  const splitLocation = location.pathname.split('/')
  const currentLocation = splitLocation[splitLocation.length - 2].toLowerCase()

  return (
    <Section className="mb-5 pb-lg-5">
      <div className="container">
        <Content content={fields.description} className="mb-5" />
        <div className="d-flex mb-4">
          <div className="custom-swiper-button-prev me-3" role="button">
            Vorige
          </div>
          <div className="custom-swiper-button-next" role="button">
            Volgende
          </div>
        </div>

        <Swiper
          navigation={{
            prevEl: '.custom-swiper-button-prev',
            nextEl: '.custom-swiper-button-next',
          }}
          breakpoints={{
            0: {
              slidesPerView: 2,
            },
            768: {
              slidesPerView: 3,
            },
          }}
          spaceBetween={20}
        >
          {relatedPosts.length > 0 &&
            relatedPosts
              .filter(
                (post) =>
                  post.node.title.replace(/\s+/g, '-').toLowerCase() !==
                  currentLocation
              )
              .map((post, index: number) => {
                const categories = post.node.projectcategories.nodes.map(
                  (node: any) => node
                )

                const [isHover, setHover] = useState<boolean>(false)

                return (
                  <SwiperSlide key={index}>
                    <Post
                      to={post.node.uri}
                      className="position-relative d-flex flex-column justify-content-end"
                    >
                      <motion.div
                        role="button"
                        onHoverStart={() => setHover(true)}
                        onHoverEnd={() => setHover(false)}
                        className="h-100"
                      >
                        <ImageWrapper className="h-100 overflow-hidden">
                          <motion.div
                            initial={{ scale: 1 }}
                            animate={isHover ? { scale: 1.1 } : { scale: 1.0 }}
                            exit={{ scale: 1 }}
                            transition={{ duration: 0.25 }}
                            className="h-100"
                          >
                            <Image
                              image={post.node.projectdetail.highlightedimage}
                              alt=""
                              className="h-100"
                            />
                          </motion.div>
                        </ImageWrapper>
                      </motion.div>
                      <PostInner className="position-relative p-sm-4 py-3 px-2">
                        <h2>{post.node.title}</h2>
                        {/* <span>{category.name}</span> */}
                        {categories.map((edge: any, categoryIndex: number) => (
                          <React.Fragment key={categoryIndex}>
                            {categories.length > 1 ? (
                              <>
                                {categoryIndex !== categories.length - 1 ? (
                                  <span>{`${edge.name}, `}</span>
                                ) : (
                                  <span>{edge.name}</span>
                                )}
                              </>
                            ) : (
                              <span>{edge.name}</span>
                            )}
                          </React.Fragment>
                        ))}
                      </PostInner>
                    </Post>
                  </SwiperSlide>
                )
              })}
        </Swiper>
        {relatedPosts.filter(
          (post) =>
            post.node.title.replace(/\s+/g, '-').toLowerCase() !==
            currentLocation
        ).length < 1 && (
          <>
            <h2>Helaas!</h2>
            <p>
              We kunnen geen gerelateerde projecten vinden. Mogelijk zijn er nog
              geen (andere) projecten in deze categorie.
            </p>
          </>
        )}
      </div>
      <div className="d-flex justify-content-center mt-5 pt-5">
        <ButtonPrimary to={fields?.link?.url || '#'}>
          {fields?.link?.title}
        </ButtonPrimary>
      </div>
    </Section>
  )
}

export default RelatedProjects
